import React from 'react';

const ArrowIcon = () => (
  <div className="scaptcha-icon-container">
    <svg
      className="scaptcha-icon-light"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 3.44 2.728"
      height="10.312"
      width="13"
    >
      <g transform="matrix(10.37411 0 0 10.37411 -5.49 -9.923)">
        <path
          d="M.53 1.088c0-.012.008-.02.02-.02h.228L.71 1C.693.981.697.972.706.962c.01-.01.02-.006.034.006l.12.12-.125.126c-.01.008-.025.006-.03-.002-.01-.014-.008-.021.01-.04l.063-.063H.55C.533 1.11.53 1.1.53 1.09z"
          fill="#202020"
        />
      </g>
    </svg>
    <svg
      className="scaptcha-icon-dark"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 3.44 2.728"
      height="10.312"
      width="13"
    >
      <g transform="matrix(10.37411 0 0 10.37411 -5.49 -9.923)">
        <path
          d="M.53 1.088c0-.012.008-.02.02-.02h.228L.71 1C.693.981.697.972.706.962c.01-.01.02-.006.034.006l.12.12-.125.126c-.01.008-.025.006-.03-.002-.01-.014-.008-.021.01-.04l.063-.063H.55C.533 1.11.53 1.1.53 1.09z"
          fill="#c6c6c6"
        />
      </g>
    </svg>
  </div>
);

export default ArrowIcon;
