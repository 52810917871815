import React, { useState } from "react";
import PropTypes from "prop-types";
import { Buffer } from "buffer";
import { useEffect } from "react";

import { ArrowIcon, SuccessIcon, FailureIcon } from "./icons";

const imageDataUrl = (image) => {
  if (image) {
    return `data:image/png;base64,${Buffer.from(image).toString("base64")}`;
  }
};

const slider = {
  default: {
    track: "scaptcha-card-slider-track-default",
    control: "scaptcha-card-slider-control-default",
    icon: <ArrowIcon />,
  },
  active: {
    track: "scaptcha-card-slider-track-active",
    control: "scaptcha-card-slider-control-active",
    icon: <ArrowIcon />,
  },
  success: {
    track: "scaptcha-card-slider-track-success",
    control: "scaptcha-card-slider-control-success",
    icon: <SuccessIcon />,
  },
  failure: {
    track: "scaptcha-card-slider-track-failure",
    control: "scaptcha-card-slider-control-failure",
    icon: <FailureIcon />,
  },
};

const Challenge = ({ text, captcha, completeCaptcha, setActiveModal,setCaptchaDetail,captchaDetail }) => {
  const [sliderVariant, setSliderVariant] = useState(slider.default);
  const [resultTrue, setResultTrue] = useState(false);
  const [resultFalse, setResultFalse] = useState(false);
  const [solving, setSolving] = useState(false);
  const [submittedResponse, setSubmittedResponse] = useState(false);
  const [origin, setOrigin] = useState({
    x: 0,
    y: 0,
  });
  const [trail, setTrail] = useState({
    x: [0],
    y: [0],
  });

  // Converts distances along the control track to corresponding distances moved by the puzzle piece
  const scaleSliderPosition = (x) => 5 + 0.86 * x;

  const handleStart = (e) => {

    if (submittedResponse) return;
    setOrigin({
      x: e.clientX || e.touches[0].clientX,
      y: e.clientY || e.touches[0].clientY,
    });
    setSolving(true);
    setSliderVariant(slider.active);
  };

  const handleMove = (e) => {
    if (!solving || submittedResponse) return;
    const move = {
      x: (e.clientX || e.touches[0].clientX) - origin.x,
      y: (e.clientY || e.touches[0].clientY) - origin.y,
    };
    if (move.x > 250 || move.x < 0) return; // Don't update if outside bounds of captcha
    setTrail({
      x: trail?.x.concat([move.x]),
      y: trail?.y.concat([move.y]),
    });
  };

  const handleEnd = async (event) => {

    event.stopPropagation();
    event.preventDefault();

    try {
      if (!solving || submittedResponse) return;
      setSubmittedResponse(true);
      completeCaptcha(
        scaleSliderPosition(trail?.x[trail?.x?.length - 1]),
        trail
      ).then((validated) => {
        setSliderVariant(validated ? slider?.success : slider?.failure);
        if (validated === true) {
          setResultTrue(true);
          setTimeout(() => {
            setActiveModal(false);
          }, 3000);
        } else {
          setResultFalse(true);
        }
      });
    } catch (error) {
      console.log("error", error);
    }
  };

  const handleEnter = () => {
    if (solving || submittedResponse) return;
    setSliderVariant(slider?.active);
  };

  const handleLeave = (event) => {
    event.stopPropagation();
    event.preventDefault();
    if (solving) return;
    setSliderVariant(slider?.default);
  };
  useEffect(() => {
    if (captcha) {
      setTimeout(() => {
        setCaptchaDetail(true);
      }, 3000);
    }
  }, [captcha]);

  return (
    <div
      className={`${
        resultFalse
          ? "translate-card-left"
          : captchaDetail
          ? "translate-card-right"
          : ""
      }`}
      style={{ position: "relative" }}
      draggable="false"
      onMouseMove={handleMove}
      onTouchMove={handleMove}
      onTouchEnd={handleEnd}
      onMouseUp={handleEnd}
      onMouseLeave={handleEnd}
    >
      <div style={{ position: "relative" }}>
        <div
          className={
            (resultTrue && "layer-success") || (resultFalse && "layer-failed")
          }
          style={{
            display: resultTrue || resultFalse ? "flex" : "none",
            color: "white",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {resultTrue
            ? "you beat 100% of users"
            : resultFalse
            ? "Please try again"
            : ""}
        </div>
        <div
          className={
            resultTrue || resultFalse
              ? "scaptcha-card-background"
              : "scaptcha-card-background scaptcha-card-element"
          }
          style={{
            backgroundImage: `url('${imageDataUrl(captcha?.background)}')`,
          }}
        />
      </div>
      <div
        className="scaptcha-card-slider-puzzle scaptcha-card-element"
        style={{
          backgroundImage: `url('${imageDataUrl(captcha.slider)}')`,
          left: `${scaleSliderPosition(trail.x[trail.x.length - 1])}px`,
        }}
        onMouseDown={handleStart}
        onTouchStart={handleStart}
      />
      <div className="scaptcha-card-slider-container scaptcha-card-element">
        <div className="scaptcha-card-slider-track scaptcha-card-element" />
        <div
          className="scaptcha-card-slider-label scaptcha-card-element"
          style={{ opacity: solving ? 0 : 1 }}
        >
          <span>{text.challenge}</span>
        </div>
        <div
          className={`scaptcha-card-slider-mask ${sliderVariant.track} scaptcha-card-element`}
          style={{ width: `${trail.x[trail.x.length - 1] + 30}px` }}
        />
        <div
          className="scaptcha-card-slider-container scaptcha-card-element"
          draggable="false"
        />
        <div
          className={`scaptcha-card-slider-control ${sliderVariant.control} scaptcha-card-element`}
          style={{ left: `${trail.x[trail.x.length - 1]}px` }}
          onMouseDown={handleStart}
          onTouchStart={handleStart}
          onMouseEnter={handleEnter}
          onMouseLeave={handleLeave}
        >
          {sliderVariant.icon}
        </div>
      </div>
    </div>
  );
};

Challenge.propTypes = {
  completeCaptcha: PropTypes.func.isRequired,
  captcha: PropTypes.shape({
    slider: PropTypes.shape({
      type: PropTypes.string,
      data: PropTypes.arrayOf(PropTypes.number),
    }),
    background: PropTypes.shape({
      type: PropTypes.string,
      data: PropTypes.arrayOf(PropTypes.number),
    }),
  }).isRequired,
  text: PropTypes.shape({
    anchor: PropTypes.string,
    challenge: PropTypes.string,
  }).isRequired,
};

export default Challenge;
