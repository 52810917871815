import React, { useRef, useState } from "react";
import PropTypes from "prop-types";
import Card from "./card";
import { SuccessIcon } from "./icons";
import Modal from "./modal/Modal";
import Challenge from "./challenge";
import './style/custom.css'

const Anchor = ({
  text,
  fetchCaptcha,
  submitResponse,
  verified,
  setVerified,
}) => {
  const [open, setOpen] = useState(false);
  const [captcha, setCaptcha] = useState(false);
  const [completeCaptchaResult, setCompleteCaptchaResult] = useState(false);
  const [loading, setLoading] = useState(false);
  const [key, setKey] = useState(Math.random());
  const isMounted = useRef(false);

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
    handleRefreshCaptcha();
    setLoading(true);
    setVerified(false);
  };

  const handleKey = (e) => {
    if (e.code === "Enter" || e.code === "Space") {
      setOpen(true);
    } else if (e.key === "Escape") {
      setOpen(false);
    }
  };

  const handleRefreshCaptcha = () => {
    isMounted.current = true;
    refreshCaptcha();
    return () => {
      isMounted.current = false;
    };
  };

  const refreshCaptcha = () => {
    setCompleteCaptchaResult(false);
    fetchCaptcha().then((newCaptcha) => {
      if (!isMounted.current) return;
      setKey(Math.random());
      setCaptcha(newCaptcha);
      setLoading(false);
    });
  };

  const completeCaptcha = (response, trail) =>
    new Promise((resolve) => {
      submitResponse(response, trail).then((verified) => {
        if (verified) {
          resolve(true);
        } else {
          setCompleteCaptchaResult(true);
          resolve(false);
          setTimeout(() => {
            refreshCaptcha();
          }, 1000);
        }
      });
    });
  const [captchaDetail, setCaptchaDetail] = useState(true);
  const [resultFalse, setResultFalse] = useState(false);

  return (
    <div
      className={`${
        completeCaptchaResult === true
          ? "shake-effect btn-wrapper"
          : "btn-wrapper"
      }`}
    >
      <div
        className={`${loading && "container loading-button"} ${
          captcha
            ? "expand email"
            : verified
            ? " email success-button"
            : "email btn"
        }`}
        onClick={handleOpen}
      >
        <div className="from">
          <div className="from-contents">
            <button
              suppressHydrationWarning
              type="button"
              style={{ display: "none" }}
              className={`scaptcha-anchor-checkbox ${
                !verified && "scaptcha-anchor-checkbox-default"
              } scaptcha-anchor-element `}
              onKeyUp={handleKey}
            >
              {verified && <SuccessIcon />}
            </button>
            <div
              className={`${
                verified
                  ? "varified_text"
                  : "scaptcha-anchor-label scaptcha-anchor-element"
              }`}
            >
              {loading
                ? "Loading"
                : verified
                ? "Varification Success"
                : text.anchor}
            </div>
          </div>
        </div>
        {captcha && (
          <Modal
            activeModal={captcha}
            setActiveModal={setCaptcha}
            refreshCaptcha={refreshCaptcha}
            setResultFalse={setResultFalse}
          >
            <Challenge
              key={key}
              text={text}
              captcha={captcha}
              completeCaptcha={completeCaptcha}
              setActiveModal={setCaptcha}
              captchaDetail={captchaDetail}
              setCaptchaDetail={setCaptchaDetail}
              setResultFalse={setResultFalse}
              resultFalse={resultFalse}
            />
          </Modal>
        )}
      </div>
    </div>
  );
};

Anchor.propTypes = {
  fetchCaptcha: PropTypes.func.isRequired,
  submitResponse: PropTypes.func.isRequired,
  text: PropTypes.shape({
    anchor: PropTypes.string,
    challenge: PropTypes.string,
  }).isRequired,
  verified: PropTypes.bool.isRequired,
};

export default Anchor;
