import React, { Children, useState } from "react";
import "../../slider-captcha/style/custom.css";
import { MdOutlineCancel } from "react-icons/md";
import { RiLoopRightLine } from "react-icons/ri";

const Modal = ({ activeModal, setActiveModal, children, refreshCaptcha }) => {
  return (
    <>
      {/* <div className="modal_container"> */}
      <div onClick={() => setActiveModal(true)}>
        <div className="to">
          <div className="to-contents">
            <div className="top">
              <div className="name-large">Slide to Complete the Captcha</div>
            </div>
            <div
              className="bottom"
              onClick={(event) => {
                event.stopPropagation();
              }}
            >
              {children}
              <div className="x-touch">
                <div
                  onClick={(event) => {
                    setActiveModal(false);
                    event.stopPropagation();
                  }}
                  style={{ marginRight: ".5em" }}
                >
                  <MdOutlineCancel fontSize={20} />
                </div>
                <div
                  style={{ marginRight: ".5em" }}
                  onClick={(event) => {
                    refreshCaptcha();
                    event.stopPropagation();
                  }}
                >
                  <RiLoopRightLine fontSize={20} />
                </div>
              </div>
            </div>
            <div></div>
          </div>
        </div>
      </div>
      {/* </div> */}
    </>
  );
};

export default Modal;
