import React, { useState } from "react";
import PropTypes from "prop-types";

import { ToastContainer } from "react-toastify";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Anchor from "./anchor";
import Theme from "./theme";

var captchaStatus;
var createCaptcha;
var rand;
var accessToken;

const SliderCaptcha = ({
  sitekey,
  callback,
  // create,
  // verify,
  variant,
  text,
}) => {
  const [verified, setVerified] = useState(false);
  const [userKey, setUserKey] = useState(sitekey);
  const baseURL = 
"https://api.gotcha.land/api/v1";

  // ==========================================================================

  const fetchCaptcha = (create) => () =>
    create instanceof Function
      ? create() // Use provided promise for getting background and slider
      : fetch(create, {
          // Use create as API URL for fetch
          method: "POST",
          headers: {
            // "Access-Control-Allow-Credentials": "true",
            "Access-Control-Allow-Origin": "*",
            "Content-Type": "application/json",
            "X-Requested-With": "XMLHttpRequest",
          },
          body: JSON.stringify({ siteKey: sitekey }),
        })
          .then(async (res) => {
            if (res.status >= 400) {
              if (res.status == 500) {
                toast.error("Internal Server Error!", {
                  position: "top-right",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                  theme: "light",
                });
              }

              toast.error("Please try again later!", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
              });

              setTimeout(() => {
                throw new Error("Server responds with error!");
              }, 2500);
            }

            const responseData = await res.json();

            captchaStatus = responseData.success;
            createCaptcha = responseData.data.create.data;
            rand = responseData.data.rand;
            accessToken = responseData.data.token;

            if (captchaStatus) {
              return createCaptcha;
            } else {
              toast.warn("Please try again later!", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
              });
            }
          })
          .catch((error) => {
            // TypeError: Failed to fetch
            console.log(error);
            toast.error(`There was an error!`, {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            });
          });

  const fetchVerification = (verify) => (response, trail) => {
    return new Promise((resolve, reject) => {
      if (verify instanceof Function) {
        verify(response, trail)
          .then((result) => {
            resolve(result);
          })
          .catch((error) => {
            reject(error);
          });
      } else {
        fetch(verify, {
          method: "POST",
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Content-Type": "application/json",
            "X-Requested-With": "XMLHttpRequest",
            Authorization: `Bearer ${accessToken}`,
          },
          body: JSON.stringify({
            response,
            trail,
            rand,
          }),
        })
          .then(async (message) => {
            const responseData = await message.json();
            resolve(responseData);
          })
          .catch((error) => {
            console.log(error);
            toast.error(`There was an error!`, {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            });
            reject(error);
          });
      }
    });
  };

  // ==========================================================================

  const submitResponse = async (response, trail) => {
    return new Promise((resolve, reject) => {
      fetchVerification(`${baseURL}/vCaptcha`)(response, trail)
        .then((verification) => {
          if (
            verification?.error ||
            verification?.result !== "success" ||
            !verification?.token
          ) {
            resolve(false);
          } else {
            setTimeout(() => {
              callback(verification.token);
              setVerified(true);
            }, 500);
            resolve(true);
          }
        })
        .catch((error) => {
          console.error("Error occurred: ", error);
          reject(error);
        });
    });
  };

  return (
    <div className="scaptcha-container">
      <Theme variant={variant} />
      <ToastContainer />
      <Anchor
        text={text}
        fetchCaptcha={fetchCaptcha(`${baseURL}/Captcha`)}
        submitResponse={submitResponse}
        verified={verified}
        setVerified={setVerified}
      />
    </div>
  );
};

SliderCaptcha.propTypes = {
  callback: PropTypes.func,
  sitekey: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  create: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  verify: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  variant: PropTypes.string,
  text: PropTypes.shape({
    anchor: PropTypes.string,
    challenge: PropTypes.string,
  }),
};

SliderCaptcha.defaultProps = {
  callback: (token) => console.log(token), // eslint-disable-line no-console
  sitekey: "site-730af36e-3acf-4bd6-9c46-f76c438c8147-key",
  create: "captcha/create",
  verify: "captcha/verify",
  variant: "light",
  // variant: "dark",
  text: {
    anchor: "Click to verify",
    challenge: "",
  },
};

export default SliderCaptcha;
