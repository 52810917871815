import React, { useState } from "react";
import axios from "axios";
import SliderCaptcha from "./components/slider-captcha/index";
export const App = () => {
  const [captchaTokenVerify, setCaptchaTokenVerify] = useState(false);

  const verifiedCallback = (token) => {
    if (token) {
      setCaptchaTokenVerify(true);
    }
  };

  return (
    <div className="App">
      <SliderCaptcha
        sitekey="siTe-600bd7f6-e5f6-4391-9732-74f7eab6001b-kEy"
        callback={verifiedCallback}
      />
    </div>
  );
};

export default App;
